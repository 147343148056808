'use strict';

var ageGateEventTriggered = false;

function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') // eslint-disable-line
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) // eslint-disable-line
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function showAgeGateModal() {
    const $workshopAgeGateModal = $('#workshopAgeGateModal');
    const ageGatePassed = readCookie("ageGatePassed");
    $workshopAgeGateModal.addClass('activeModal');
    if (ageGatePassed === "true" && $workshopAgeGateModal.length) {
        $workshopAgeGateModal.modal('hide');
    } else {
        $workshopAgeGateModal.modal({
            backdrop: 'static',
            keyboard: false
        });
        $('#workshopAgeGateModal .continue').prop('disabled', true);
    }
}

$('.footer-item h5').on('click', function (event) {
    event.preventDefault();
    const $footerItem = $('.footer-item');
    const $footerItemHeader = $(this);
    const $elementHeader = $footerItemHeader.next();
    const $accordionContent = $footerItem.find('ul,.guest-experience');
    const $accordionTitle = $footerItem.find('h5');

    if ($elementHeader.hasClass('show')) {
        $elementHeader.removeClass('show');
        $elementHeader.slideUp(350);
        $footerItemHeader.removeClass('active');
    } else {
        $accordionContent.removeClass('show');
        $accordionContent.slideUp(350);
        $accordionTitle.stop().removeClass('active');
        $elementHeader.toggleClass('show');
        $elementHeader.slideToggle(350);
        $footerItemHeader.addClass('active');
    }
});

$('body').on('click', '#ageGateModal .continue', function () {
    createCookie('ageGate', 'Confirmed', '90');
});

$('body').on('click', '#ageGateModal .go-back-btn', function () {
    const location = $(this).attr('data-href');
    window.location.href = location;
});

function startFtLgdeactiveq() {
    if (typeof $ === "function") {
        clearInterval(deactiveQcheckFtLg);
        document.cookie = 'dw_cookie_leadgen_seen=1; expires=Mon, 2 deactivean 2023 12:00:00 UTC; path=/';

        var $leadGen = $('.footer-lead-gen');

        $('body').find('#footLg-email').on('change blur', function () {
            let footLgEmail = $(this).val();
            let invalidMsg = $(this).parents('.form-group').find('.invalid-feedback').data('error-msg');

            if (!isValidEmailAddress(footLgEmail)) {
                $(this).addClass('is-invalid');
                $(this).parents('.form-group').find('.invalid-feedback').text(invalidMsg);
            }
        });

        $leadGen.find('#ft-email-signup').on('click', function (e) {
            e.preventDefault();

            var footLgEmail = $leadGen.find('#footLg-email').val();
            let $footLgOptIn = $leadGen.find('#footLg-opt-in');
            const emailErrorMsg = $('.footer-form-email').val();
            const checkboxErrorMsg = $('.footer-form-checkbox').val();
            let invalidMsg = $leadGen.find('#footLg-email').parents('.form-group').find('.invalid-feedback').data('error-msg');
            var validEmail = isValidEmailAddress(footLgEmail);

            if (validEmail && $footLgOptIn.is(":checked")) {
                var $button = $(this);
                var url = $('.footer-form-submit-url').val();
                var source = $('.footer-form-source').val();
                var brand = $('.footer-form-brand').val();

                var obj = {
                    emailAddress: footLgEmail,
                    source: source,
                    brand: brand
                };

                $.ajax({
                    url: url,
                    type: 'GET',
                    dataType: 'json',
                    data: obj,
                    success: function (data) {
                        if (data.success) {
                            $leadGen.find('#footLg-email').val(footLgEmail).attr('disabled', 'disabled');
                            $button.attr('disabled', 'disabled');
                            $leadGen.find('#footLg-opt-in').attr('checked', true).attr('disabled', 'disabled');
                            $leadGen.find('.lg-success').fadeIn();
                            $leadGen.find('#footLg-opt-in').removeClass('is-invalid');
                            $leadGen.find('#footLg-opt-in').parents('.form-group').find('.invalid-feedback').empty();
                        } else {
                            $leadGen.find('.lg-error').fadeIn();
                        }
                    },
                    error: function () {
                        $leadGen.find('.lg-error').fadeIn();
                    }
                });
            } else {
                e.preventDefault();
                if (!validEmail) {
                    $leadGen.find('#footLg-email').addClass('is-invalid');
                }
                if (!$footLgOptIn.is(":checked")) {
                    $leadGen.find('#footLg-opt-in').addClass('is-invalid');
                }
                if (footLgEmail !== "") {
                    if (!validEmail) {
                        $leadGen.find('#footLg-email').siblings('.invalid-feedback').text(invalidMsg);
                    }
                    if ($footLgOptIn.is(":checked")) {
                        $leadGen.find('#footLg-opt-in').removeClass('is-invalid');
                    } else {
                        $leadGen.find('#footLg-opt-in').parents('.form-group').find('.invalid-feedback').text(checkboxErrorMsg);
                    }
                } else {
                    if (!validEmail) {
                        $leadGen.find('#footLg-email').siblings('.invalid-feedback').text(emailErrorMsg);
                    }
                    if ($footLgOptIn.is(":checked")) {
                        $leadGen.find('#footLg-opt-in').removeClass('is-invalid');
                    } else {
                        $leadGen.find('#footLg-opt-in').parents('.form-group').find('.invalid-feedback').text(checkboxErrorMsg);
                    }
                }
            }
        });
    }
}

function isValidEmailAddress(footLgEmail) {
    var pattern = /^([\w+\-]+\.)*[\w+\-]+@([\w+\-]+\.)*[\w+\-]+\.[a-zA-Z]{2,4}$/;
    return pattern.test(footLgEmail);
}

let notOne = !1,
    bottomOnMobile = -265,
    bottomOnDesktop = -300,
    screenWidth = $(window).width(),
    resizeScreen = screenWidth <= 768 ? bottomOnMobile : bottomOnDesktop;

$(window).on("resize", function () {
    screenWidth = $(window).width();
    resizeScreen = screenWidth < 768 ? bottomOnMobile : bottomOnDesktop;
    notOne ? deactive(resizeScreen) : $(".marketing-drawer").css("bottom", resizeScreen);
});

function deactive(e) {
    notOne = !1;
    let $marketingDrawer = $(".marketing-drawer");
    $(".menu-overlay-back").hide();
    $marketingDrawer.find("span.arrow").toggleClass('active');
    $marketingDrawer.removeClass("expanded");
    $marketingDrawer.find(".control span.viewall").text("View All");
    $marketingDrawer.animate({
        bottom: e
    }, 500);
}

function activeOffers() {
    $(".marketing-drawer").on('click', '.control', function () {
        notOne ? (screenWidth = $(window).width(),
            deactive(resizeScreen = screenWidth < 768 ? bottomOnMobile : bottomOnDesktop)) : (!function () { // eslint-disable-line
                notOne = !0;
                let $marketingDrawer = $(".marketing-drawer");
                $(".menu-overlay-back").show();
                $(".page").addClass("expanded");
                $marketingDrawer.find("span.arrow").toggleClass('active');
                $marketingDrawer.addClass("expanded");
                $marketingDrawer.find(".control span.viewall").text("Close");
                $marketingDrawer.animate({
                    bottom: 0
                }, 500);
            }(), $(".menu-overlay-back").on("click", function () {
                screenWidth = $(window).width();
                deactive(resizeScreen = screenWidth < 768 ? bottomOnMobile : bottomOnDesktop);
            }));
    });

    $(".marketing-drawer").on("click", "a.disc-link", function () {
        let $mainPromoLightbox = $("#mainPromoLightbox");
        let $disclaimerContent = $(this).closest('.slide-content-wrap').find(".disclaimer p").html();
        $mainPromoLightbox.find(".modal-body p").html($disclaimerContent);
        $mainPromoLightbox.modal('show');
    });
}

$(window).on('load', function () { // if PD drawer is active, count Promo Cards in Mktg Drawer
    const getDrawer = document.getElementById('drawerCarousel');
    if (getDrawer) {
        const getCards = getDrawer.querySelectorAll(`.promo-slide`);
        if (getCards.length > 0) {
            const cardCount = getCards.length;
            const showCount = document.getElementById("theOffers");
            showCount.innerHTML += " (" + cardCount.toString() + ")";
            getDrawer.removeAttribute("style");
        } else {
            getDrawer.style.display = "none";
        }
    }
});

$(window).on('load', function () { // even out height of Promo Cards in carousels
    const promoCards = document.querySelectorAll('.pcdinner:not(#drawerCarousel *)');
    var thisCard = 0;
    var tallCard = 0;

    if (promoCards) {
        promoCards.forEach(eachCard => {
            thisCard = eachCard.offsetHeight;
            if (thisCard > tallCard) {
                tallCard = thisCard;
            }
        });

        promoCards.forEach(indCard => {
            indCard.style.height = `${tallCard}px`;
        });
    }
});

var deactiveQcheckFtLg = setInterval(startFtLgdeactiveq, 1000);

$(document).ready(function () {
    const ageGateModal = $('#ageGateModal');
    if (ageGateModal.length && readCookie('ageGate') === null) {
        ageGateModal.modal();
    }
    activeOffers();
});

$(document).on('focus', '#footLg-email, #login-form-email, #email-guest, #subscriberEmail, #email , #registration-form-email', function () {
    showAgeGateModal();
});

$(document).on("click", function (event) {
    const $workshopAgeGateModal = $('#workshopAgeGateModal');
    const ageGatePassed = readCookie("ageGatePassed");
    if ($workshopAgeGateModal.length && $workshopAgeGateModal.hasClass('activeModal')) {
        if ($workshopAgeGateModal.attr('data-gtm-enable') && !ageGateEventTriggered && ageGatePassed !== 'true') {
            window.dataLayer.push(JSON.parse($workshopAgeGateModal.attr('data-gtm-data')));
            ageGateEventTriggered = true;
        }
        if (ageGatePassed === "true") {
            $workshopAgeGateModal.modal('hide');
        } else {
            event.preventDefault();
            $workshopAgeGateModal.modal({
                backdrop: 'static',
                keyboard: false
            });
        }
    }
});

$(document).on("change blur keyup", "#birthMonth", function () {
    var monthArr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    let birthMonth = $("#birthMonth").val();
    let birthYear = $("#birthYear").val();
    // eslint-disable-next-line no-undef
    if ((birthMonth !== '' && jQuery.inArray(birthMonth, monthArr) !== -1) && birthYear.length === 4 && birthYear.length === 4 && birthYear >= 1900 && birthYear <= 3000) {
        $('#workshopAgeGateModal .continue').prop('disabled', false);
    } else {
        $('#workshopAgeGateModal .continue').prop('disabled', true);
        $('#birthMonth').val(birthMonth.replace(/[^0-9\.]/g, ''));
        if (birthMonth.length > 2) {
            $('#birthMonth').val(birthMonth.slice(0, 2));
        }
    }
});

$(document).on("change blur keyup", "#birthYear", function () {
    var monthArr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    let birthMonth = $("#birthMonth").val();
    let birthYear = $("#birthYear").val();
    // eslint-disable-next-line no-undef
    if ((birthMonth !== '' && jQuery.inArray(birthMonth, monthArr) !== -1) && birthYear.length === 4 && parseInt(birthYear) >= 1900 && parseInt(birthYear) <= 3000) {
        $('#workshopAgeGateModal .continue').prop('disabled', false);
    } else {
        $('#workshopAgeGateModal .continue').prop('disabled', true);
        $('#birthYear').val(birthYear.replace(/[^0-9\.]/g, ''));
        if (birthYear.length > 4) {
            $('#birthYear').val(birthYear.substring(0, 4));
        }
    }
});

$(document).on("click", "#workshopAgeGateModal .continue", function () {
    let birthMonth = parseInt($("#birthMonth").val(), 10);
    let birthYear = parseInt($("#birthYear").val(), 10);
    const $workshopAgeGateModal = $('#workshopAgeGateModal');
    let url = $('#workshopAgeGateModal').data('continue-url');
    let isHomePage = $('#homepage').length > 0;
    let queryString = '?year=' + birthYear + '&month=' + birthMonth;
    $.ajax({
        url: url + queryString,
        method: 'GET',
        success: function (data) {
            const isAdult = data.isAdult;
            if (isAdult) {
                createCookie("ageGatePassed", "true", 60);
                $workshopAgeGateModal.removeClass('activeModal');
                try {
                    if (data && data.dataLayerObj && data.dataLayerObj.isGTMEnabled) {
                        window.dataLayer.push(JSON.parse(data.dataLayerObj.data));
                    }
                } catch (error) {
                    window.console.error('\nfooter.js: error in {0}', error);
                }
            } else if (!isHomePage) {
                window.location = data.redirectURL;
            } else {
                //
            }
            $workshopAgeGateModal.modal('hide');
        }
    });
});
